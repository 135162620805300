<template>
<div>
    <div class="body">
        <CriticalBanner />
        <!-- <Navigation/> -->
<!--        <InfoBanner />-->
        <BannerSearch />
        <HelpTopicList :content="contentMisc.helpTopics" />
        <ChatBot />
        <Videos class="pb0" />
        <GetHelp class="pt0" />
        <Footer />
        <Gradients />
    </div>
</div>
</template>
<script>

import BannerSearch from '../components/HelpSupport/BannerSearch';
import HelpTopicList from '../components/HelpSupport/HelpTopicList';
import Videos from '../components/HelpSupport/Videos';
import ChatBot from '../components/HelpSupport/ChatBot';
import GetHelp from '../components/HelpSupport/GetHelp';
import Footer from '../components/Footer';
import Gradients from '../components/Gradients';
// import InfoBanner from '../components/HelpSupport/InfoBanner';
import CriticalBanner from '../components/HelpSupport/CriticalBanner';
// import Navigation from '../components/Navigation'; //Enable for dev
import apiHelp from '../api-help'

export default {
    name: 'Support',
    data() {
        return {
            contentMisc: []
        }
    },    
    mounted() {
        apiHelp.contentMisc().then(res => { this.contentMisc = res });

    },
    components: {
        BannerSearch,
        HelpTopicList,
        Videos,
        ChatBot,
        GetHelp,
        Footer,
        Gradients,
        // InfoBanner,
        CriticalBanner,
        // Navigation
    }
};
</script>
<style lang="scss">
.has-critical {
    top: 55px!important;
    @media only screen and (min-width: $laptop-min-size) {
        top: 32px!important;
    }
}
.banner-search{
    &.is-lower{
      @media only screen and (max-width: $phone-max-size){
        padding: 47px 0;
      }
    }
}
</style>