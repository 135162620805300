<template>
    <section class="topicView bg-grey" style="padding-top: 0">
        <div class="topicView-inner">
          <div class="page-title">
            <div class="grid-container narrow">
              <div class="grid-x">
                <div class="cell small-12">
                  <div class="breadcrumb">
                    <ul class="breadcrumb-list">
                      <li class="breadcrumb-item">
                        <a class="link du-magenta" :href="userType === 'business' ? breadcrumbsCheck + content.helpURL+'&userType='+userType : content.helpURL+'&userType='+userType">{{content.home}}</a>
                      </li>
                      <li v-for="(bread,i) in breadcrumbs" :key="i" class="breadcrumb-item">
                        <a :href="content.breadcrumbTopicURL+'?topicName='+bread.id+'&userType='+userType" class="link du-magenta">{{bread.name}}</a>
                      </li>
                      <li v-if="subTopics.title && tagService === 'true'" class="breadcrumb-item">
                        <a>{{subTopics.title}}</a>
                      </li>
                    </ul>
                  </div>
                  <h3 class="headline2" v-if="topicDetails.topicBreadcrumb">{{this.topicDetails.topicBreadcrumb[0].name}}</h3>
                  <h3 class="headline2" v-if="subTopics.title && tagService === 'true'">{{subTopics.title}}</h3>
                </div>
              </div>
            </div>
          </div>
          <!-- Top tabs -->
          <div class="tab-wrapper">
            <div class="grid-container narrow">
              <div class="grid-x">
                <div class="cell small-12 d-flex flex-center flex-middle">
                  <a href="" @click.prevent="scrollRight()" v-if="horizScroll" class="scroll-right">
                    <img src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-left.svg" />
                  </a>
                  <ul class="topicView-tab">
                    <li :class="{'topicView-tab-nav':true, 'active': isActive === topic.topic.topicURL}" v-for="(topic,i) in topicDetails.topicTree" :key="i+'topic'">
                      <span @click="getSubtopic(topic.topic.topicURL, topic.topic.isSearchTagService, topic.topic.identifier)">{{topic.topic.name}} </span>
                    </li>
                  </ul>
                  <a href="#" @click.prevent="scrollLeft()" v-if="horizScroll" class="scroll-light">
                    <img src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div class="grid-container narrow">
            <div class="grid-x">
              <div class="cell small-12">
                <div class="topicView-tab-content card p-0">
                  <div class="topicView-tab-item active">
                    <ul class="p-0">
                      <template v-if="subTopics.topicTree">
                        <li v-for="(subtopic, i) in subTopics.topicTree" :key="i+'subtopics'">
                          <a href="javascript:void(0)" class="link black underline"  @click="getSubtopic(subtopic.topic.topicURL, subtopic.topic.isSearchTagService, subtopic.topic.identifier)">
                            {{subtopic.topic.name}}
                            <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                          </a>
                        </li>
                      </template>
                      <template v-else-if="subTopics.articles">
                        <li v-for="(article, i) in subTopics.articles" :key="i+'subtopics'">
                          <a class="link black underline" :href="'/'+content.articleURL+'?'+article.articleURL+'&tagname='+identifier+'&userType='+userType" @click="sendGA('Help & Support', 'Article', article.name, 0);">
                            {{article.name}}
                            <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                          </a>
                        </li>
                      </template>
                      <template v-else>
                        <li class="rd-loader-li" v-for="index in numberOfIterations" :key="index" >
                          <p class="headline2 rd-loader">Loader</p>
                        </li>
                      </template>
                    </ul>
                  </div>
                </div>
                          <!--  is mobile  -->
<!--                          <div v-if="isMobile">-->
<!--                            <ul class="topicView-list" v-if="checkLength()">-->
<!--                              <li class="topicView-item active">-->
<!--                                <span class="topicView-item-link subtitle2">{{topicDetails.topic.name}}</span>-->
<!--                                <div class="topicView-item-list active">-->
<!--                                  <ol>-->
<!--                                    <li v-for="(subtopic, i) in subTopics" :key="i+'subtopic1'">-->
<!--                                      <a class="link black underline" :href="content.articleURL+subtopic.alternateId+content.language+'&userType='+userType" @click="sendGA('Help & Support', 'Article', subtopic.name, 0);">-->
<!--                                        {{subtopic.name}}-->
<!--                                      </a>-->
<!--                                    </li>-->
<!--                                  </ol>-->
<!--                                </div>-->
<!--                                <span class="topicView-item-icon"></span>-->
<!--                              </li>-->
<!--                            </ul>-->
<!--                            <ul class="topicView-list" v-else>-->
<!--                              <li :class="{'topicView-item':true, 'active': isActive === topic.topic.topicURL}" v-for="(topic,i) in topicDetails.topicTree" :key="i+'topic'" :data-id="topic.topic.id">-->
<!--                                <span class="topicView-item-link subtitle2" @click="getSubtopic(topic.topic.id, topic.topic.name, topic.topic.identifier)">{{topic.topic.name}}</span>-->
<!--                                <div :class="{'topicView-item-list':true, 'active': isActive === topic.topic.id}">-->
<!--                                  <ol>-->
<!--                                    <template v-if="subTopics.length > 0">-->
<!--                                      <template v-for="subtopic in subTopics">-->
<!--                                        <template v-if="subtopic.id === topic.topic.id">-->
<!--                                          <li v-for="(element, i) in subtopic.elements" :key="i+topic.topic.id">-->
<!--                                            <template v-if="element.topic">-->
<!--                                              <a class="link black underline 2" :href="content.topicsURL+element.topic.id+content.language+'&ptn='+topicDetails.topic.name+'&ptid='+topicDetails.topic.id+'&userType='+userType" @click="sendGA('Help & Support', 'SubTopic', element.topic.name, 0);">-->
<!--                                                {{element.topic.name}}-->
<!--                                              </a>-->
<!--                                            </template>-->
<!--                                            <template v-else>-->
<!--                                              <a class="link black underline" :href="content.articleURL+element.alternateId+content.language+'&userType='+userType" @click="sendGA('Help & Support', 'Article', element.name, 0);">-->
<!--                                                {{element.name}}-->
<!--                                              </a>-->
<!--                                            </template>-->
<!--                                          </li>-->
<!--                                        </template>-->
<!--                                      </template>-->
<!--                                    </template>-->
<!--                                    <template v-else>-->
<!--                                      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>-->
<!--                                    </template>-->
<!--                                  </ol>-->
<!--                                </div>-->
<!--                                <span class="topicView-item-icon"></span>-->
<!--                              </li>-->
<!--                            </ul>-->
<!--                          </div>-->

                            <!--  is desktop  -->



<!--                                <div v-else>-->
<!--                                  <div class="topicView-tab-content card p-0">-->
<!--                                    <div v-for="(topic,i) in topicDetails.topicTree" :key="i+'topic'" :data-id="topic.topic.id">-->

<!--                                        <ul class="p-0">-->
<!--                                          <template v-if="subTopics.length > 0">-->
<!--                                            <template v-for="subtopic in subTopics">-->
<!--                                              <template v-if="subtopic.id === topic.topic.id">-->
<!--                                                <li v-for="(element, i) in subtopic.elements" :key="i+topic.topic.id">-->
<!--                                                  <template v-if="element.topic">-->
<!--                                                    <a class="link" :href="content.topicsURL+element.topic.id+content.language+'&ptn='+topicDetails.topic.name+'&ptid='+topicDetails.topic.id" @click="sendGA('Help & Support', 'SubTopic', element.topic.name, 0);">-->
<!--                                                      {{element.topic.name}}-->
<!--                                                      <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />-->
<!--                                                    </a>-->
<!--                                                  </template>-->
<!--                                                  <template v-else>-->
<!--                                                    <a class="link" :href="content.articleURL+element.alternateId+content.language" @click="sendGA('Help & Support', 'Article', element.name, 0);">-->
<!--                                                      {{element.name}}-->
<!--                                                      <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />-->

<!--                                                    </a>-->
<!--                                                  </template>-->
<!--                                                </li>-->
<!--                                              </template>-->
<!--                                            </template>-->
<!--                                          </template>-->
<!--                                          <template v-else>-->
<!--                                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>-->
<!--                                          </template>-->
<!--                                        </ul>-->

<!--                                    </div>-->
<!--                                  </div>-->
<!--                                </div>-->


                        </div>
                    </div>
                </div>
            </div>

    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";

export default {
    name: 'TopicView',
    props: ['content'],
    data() {
        return {
            topicName: new URL(location.href).searchParams.get('topicName'),
            tagService: new URL(location.href).searchParams.get('tagservice'),
            topicDetails: [],
            subTopics: [],
            isActive: null,
            isLoaded: false,
            horizScroll: false,
            numberOfIterations: 2,
            breadcrumbs:'',
            isArabic: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
            isSearchTagService:null,
            identifier:null,
            userType : window.location.href.indexOf("business") > -1 ? 'business' : 'consumer'
        }
    },
    async mounted() {
        if (this.topicName) {
          if (this.tagService === "true") {
            await apiHelp.getKmuccQuery(this.topicName).then(res => {
              this.subTopics = res.data
              this.identifier = 'dutopic_'+this.topicName
            })
          } else {
            await apiHelp.getKmuccSubTopics(this.topicName).then(res => {
              this.topicDetails = res.data
              console.log(this.topicDetails)
              this.isSearchTagService = this.topicDetails.topicTree[0].topic.isSearchTagService
              const apiTopic = this.topicDetails.topicTree[0].topic.topicURL

              // const localTopic = localStorage.getItem('topicName')

              // const subTopicId = localTopic === "null" || localTopic === "undefined" || localTopic === "" ? apiTopic : localTopic
              // const subTopicId = this.fromBreadCrumb ? apiTopic : localTopic === "null" || localTopic === "undefined" || localTopic === "" ? apiTopic : localTopic
              this.identifier = this.topicDetails.topicTree[0].topic.identifier
              this.getSubtopic(apiTopic, this.isSearchTagService, this.identifier)
              this.breadcrumbs = this.topicDetails.topicBreadcrumb
            })
          }
        }
        // this.url = 'support-articledetail'
      setTimeout(() => {
        this.checkScroll()
        console.log(this.horizScroll)
      },1000)
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
        getSubtopic(url, apiTagService, identifier) {
          console.log('identifier', identifier)
            this.isActive = url
            localStorage.setItem("topicName", url);
            this.identifier = identifier;
            this.sendGA('Help & Support', 'Topic', name, 0);
              if(apiTagService) {
                apiHelp.getKmuccQuery(url).then(res => {
                  this.subTopics = res.data
                  this.numberOfIterations = res.data.topicTree.length
                })
              }
              else{
                apiHelp.getKmuccSubTopics(url).then(res => {
                  this.subTopics = res.data
                })
              }

        },
        subTopicInner(url){
          apiHelp.getKmuccQuery(url).then(res => {
            console.log('subtopic inner',res.data)
            this.subTopics = res.data
          })
        },
        checkLength() {
            if (JSON.parse(JSON.stringify(this.topicDetails.topicTree)).length === 0) {
                apiHelp.getSubTopics(this.topicDetails.topic.id).then(res => { this.subTopics = res.article })
                return true;
            } else {
                return false
            }
        },
      scrollLeft(){
        document.querySelector(".topicView-tab").scrollLeft += 200;
      },
      scrollRight(){
        document.querySelector(".topicView-tab").scrollLeft += -200;
      },
      checkScroll(){
        var inner = document.querySelector(".topicView-tab").offsetWidth
        var outer = document.querySelector(".tab-wrapper .grid-x").offsetWidth
        console.log('checkscroll', inner > outer)
        if(inner >= outer){
          this.horizScroll = true
        }
        else{
          this.horizScroll = false
        }

      }
    },
  computed:{
      breadcrumbsCheck(){
        return window.location.href.indexOf('/business') > -1 ? '' : 'business/'
      }
  }
}
</script>
<style lang="scss">
body{
  img.chevron{
      [dir="rtl"] &{
        transform: scaleX(-1);
    }
  }
}
.topicView {
    .grid-x.gutter-20 {
      width: calc(100% + 40px) !important;
    }
    .p-0{
      padding: 0 !important;
    }
    .grid-container {
        display: flex;
        position: relative;
    }

    &-item {
        border: 1px solid $du-border-color;
        margin: 35px 0;
        border-radius: 10px;
        position: relative;
        overflow: hidden;
        background: white;
        &-link {
            display: block;
            padding: 30px;
            cursor: pointer;
        }

        &-list {
            overflow: hidden;
            max-height: 0px;
            // opacity: 0;
            transition: max-height .3s ease-out;

            &.active {
                max-height: 100vh;
                // opacity: 1;
                transition: max-height .3s ease-out;
                overflow: auto;
            }

            ol {
                padding: 30px !important;
                border-top: 1px solid $du-border-color;
                position: relative;
                min-height: 100px;


                @media only screen and (min-width: $phone-max-size) {
                    columns: 2;
                }

                a {
                    margin-bottom: 15px;
                    display: block;
                }
            }
        }

        &.active {
            .topicView-item-icon:after {
                height: 0;
            }
        }

        &-icon {
            position: absolute;
            height: 30px;
            width: 30px;
            border: 2px solid $primary-action-color;
            top: 45px;
            border-radius: 50%;
            transform: translateY(-50%);
            pointer-events: none;

            [dir="ltr"] & {
                right: 30px;
            }

            [dir="rtl"] & {
                left: 30px;
            }

            &:before {
                position: absolute;
                content: "";
                width: 12px;
                height: 2px;
                top: 50%;
                left: 50%;
                background-color: $primary-action-color;
                transform: translate(-50%, -50%);
                border-radius: 2px;
            }

            &:after {
                transition: .3s ease-out;
                position: absolute;
                content: "";
                width: 2px;
                height: 12px;
                top: 50%;
                left: 50%;
                background-color: $primary-action-color;
                transform: translate(-50%, -50%);
                border-radius: 2px;
            }
        }
    }

    .fadeHelp-enter-active,
    .fadeHelp-leave-active {
        transition: opacity .5s;
        transition-delay: 1s;
    }

    .fadeHelp-enter,
    .fadeHelp-leave-to {
        opacity: 0;
        transition-delay: 1s;
    }
.scroll-right{
  [dir="rtl"] &{
    order: 3;
  }
}
  .scroll-left{
    [dir="rtl"] &{
      order: 1;
    }
  }
  .topicView-tab{
    display: flex;
    margin-bottom: 0;
    overflow-x: auto;
    padding: 0 20px !important;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    [dir="rtl"] &{
      order: 2;
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    li{
      span{
        padding: 25px 16px;
        display: block;
      }
      &.active{
        span{
          color: $primary-action-color
        }
      }
    }
  }
  li.topicView-tab-nav{
    white-space: nowrap;
    cursor: pointer;
    &.active {
      position: relative;
      &:before{
        content: "";
        background: $primary-action-color;
        width: 100%;
        height: 4px;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
  }

  .page-title{
    background: #fff;
    padding: 40px 0;
    color: #333;
  }
  .tab-wrapper{
    background: #fff;
    border-top: 1px solid #eee;
    a{
      flex-shrink: 0;
    }
  }
  .topicView-tab-content{
    margin-top: 30px;
    .topicView-tab-item{
      display: none;
      &.active {
        display: block;
        padding: 0 25px;
        > ul{
          padding: 0;
          li{
            a{
              padding: 25px 0;
              display: flex;
              justify-content: space-between;
              color: black;
            }
            & + li{
              border-top: 1px solid #ddd;
            }
          }

        }
      }
    }
    .rd-loader-li {
      display: inline-block;
      width: 100%;
      margin-top: 11px;
      padding-top: 20px;
      p{
        margin-bottom: 0
      }
      &+ .rd-loader-li{

        border-top: 1px solid #ddd
      }
    }
  }
}
</style>