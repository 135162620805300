<template>
    <section class="topicView grey-bg">
        <div class="topicView-inner">
            <div class="grid-container narrow">
                <div class="grid-x ">
                    <div class="cell small-12" v-if="getArticle">
                        <div class="breadcrumb" v-if="breadcrumbs.length > 0">
                            <ul class="breadcrumb-list">
                                <li class="breadcrumb-item">
                                  <a class="link du-magenta" :href="userType === 'business' ? breadcrumbsCheck + content.helpURL+'&userType='+userType : content.helpURL+'&userType='+userType">{{content.home}}</a>
                                </li>
                              <template v-if="breadcrumbs.length > 1">
                                <li v-for="(bread,i) in breadcrumbs" :key="i" class="breadcrumb-item">
                                  <a :href="content.breadcrumbTopicURL+'?topicName='+bread.id+'&userType='+userType" class="link du-magenta">{{bread.name}}</a>
<!--                                  <span v-if="$last">(last bread)</span>-->
                                </li>
                              </template>
                              <template v-else>
                                <li v-for="(bread,i) in breadcrumbs" :key="i" class="breadcrumb-item">
                                  <a :href="content.breadcrumbTopicURL+'?topicName='+bread.id+'&tagservice=true&userType='+userType" class="link du-magenta exclude-disable">{{bread.name}}</a>
                                  <!--                                  <span v-if="$last">(last bread)</span>-->
                                </li>
                              </template>
                            </ul>
                        </div>
                      <div class="grid-x gutter-20">
                      <div class="cell medium-8">
                        <div class="card">
                          <p class="headline3 mb20" v-if="getArticleName">{{getArticleName}}</p>
                          <div v-if="getArticleName" class="topicView-content" v-html="embedArticles"></div>
                          <div v-else class="text-center lds-ring-container">
                            <li class="rd-loader-li" style="width: 70%">
                              <p class="headline2 rd-loader" style="height: 30px">Loader</p>
                            </li>
                            <li class="rd-loader-li" style="width: 90%">
                              <p class="headline2 rd-loader">Loader</p>
                            </li>
                            <li class="rd-loader-li" style="width: 100%">
                              <p class="headline2 rd-loader">Loader</p>
                            </li>
                            <li class="rd-loader-li" style="width: 100%">
                              <p class="headline2 rd-loader">Loader</p>
                            </li>
                            <li class="rd-loader-li" style="width: 40%">
                              <p class="headline2 rd-loader">Loader</p>
                            </li>
                          </div>


                        </div>
                        <div class="feedback">
                          <div class="grid-container narrow">
                            <div class="grid-x gutter-20">
                              <div class="action-grid cell large-12">
                                <div class="grid-x gutter-20">
                                  <div class="cell small-12 large-7">
                                    <h5 class="subtitle1">{{content.answerTitle }}</h5>
                                    <div class="ctas">
                                      <span>{{thumbsUpCount}} {{content.thumbsText}}</span>
                                      <a href="#" @click.prevent="articleThumbsup($event)" class="icon icon-like"><img alt="like" :src="require('../../assets/icons/icon-like.png')"> </a>

                                      <a href="#" @click.prevent="articleThumbsdown($event)" class="icon icon-unlike"><img alt="unlike" :src="require('../../assets/icons/icon-unlike.png')"></a>
                                    </div>
                                    <div :class="{hide: !feedbackForm.isVisible}">
                                      <div class="feedback-form" :class="{hide: !feedbackForm.isForm}">
                                        <form name="fdForm" @submit.prevent="feedbackSubmit" method="post">

                                          <input type="hidden" name="_authkey_" :value.sync='formData._authkey_'>
                                          <input type="hidden" name="fburl" :value.sync="formData.fburl">
                                          <input type="hidden" name="rating" :value="formData.rating"/>

                                          <p class="body1">{{content.feedbackForm.title}}</p>
                                          <fieldset class="large-5 cell card">
                                            <h5 class="subtitle1">{{content.reasonTitleTxt}}</h5>
                                            <div class="card-list" v-for="(radioValue, index) in feedbackReason.codes" :key="index">
                                              <input  type="radio" name="code" @change="radioSelect(index)" :value="radioValue.id" v-model="formData.reason" :id="'reason'+index" required>
                                              <label :for="'reason'+index">{{radioValue.title}}</label>
                                            </div>
                                            <div class="group" v-if="showTextArea">
                                              <textarea  v-model="formData.description" type="text" @input="detailReason($event.target.value)"  name="description" class="control txt1" id="formfeedbackid" placeholder="" value="" required></textarea>
                                              <p :class="{help: true, 'text-danger': remaining==0}">{{instruction}}</p>
                                            </div>
                                          </fieldset>
                                          <fieldset class="group action" v-if="feedbackForm.isForm">
                                            <input name="submitFeedback" class="btn btn-filled btn-sm" :class="formIsValid ? '':'disabled'" type="submit" :value="content.feedbackForm.btnText" >
                                          </fieldset>
                                          <div class="cell small-12" v-else>
                                            <div class="text-center lds-ring-container">
                                              <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                            </div>
                                          </div>
                                        </form>
                                      </div>
                                      <div class="thankyou-msg" :class="{hide: feedbackForm.isForm}" v-if="!showLoader">
                                        <p class="headline5">{{ content.feedbackForm.successMsg }}</p>
                                      </div>
                                      <div class="cell small-12" v-else>
                                        <div class="text-center lds-ring-container" style="min-height: 50px">
                                          <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="cell small-12 large-5">
                                    <h5 class="subtitle1">{{ content.shareTitle }}</h5>
                                    <div class="ctas share-this">
                                      <a href="#" @click.prevent="shareThis()" class="icon small icon-link"><img alt="like" :src="require('../../assets/icons/icon-share.png')"></a>
                                      <a href="#" @click.prevent="sharefb()" class="icon small icon-facebook"><img alt="facebook" :src="require('../../assets/icons/icon-fb.png')"></a>
                                      <a href="#" @click.prevent="sharetw()" class="icon small icon-twitter"><img alt="twitter" :src="require('../../assets/icons/icon-tw.png')"></a>
                                      <a href="#" @click.prevent="sharewhatsapp()" class="icon small icon-whatsapp hide-for-medium" ><img alt="whatsapp" :src="require('../../assets/icons/icon-whatsapp.png')"></a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="cell large-4 chat-card" :class="isRtl ? 'pl0':'pr0'"  v-if="chatBotAllowed">
                                <div class="card" style="margin-bottom:0 !important">
                                  <h5 class="subtitle3 margin-bottom-10">{{content.contactTitle }}</h5>
                                  <button type="button" class="btn border btn-sm" style="display: inline-block" @click="showChatbot()">{{content.chatBoxBtnTxt}}</button>
                                </div>
                              </div>
                            </div>
                          </div>

                      </div>
                      </div>
                      <div class="cell medium-4 sidebar">
                        <div class="align-center" v-if="id=='PROD-72951' || id=='PROD-41420'">
                          <TroubleTicketForm :isSideBar="true" />
                        </div>
                        <div class="card" v-if="quickPayAllowed">
                          <UsefulLinks :title="content.qpTitle" :description="content.description" :icon="icon" :button="content.qpButton" :link="link" />
                        </div>
                        <div class="card" v-if="getRelatedArticles.length > 0 || additionalRelatedArticles.length > 0">
                          <p class="subtitle1 margin-bottom-10">{{content.relatedArticlesTitle}}</p>
                          <ul class="article-list">
                            <li v-for="(item, i) in getRelatedArticles" :key="i">
                              <a :href="'/'+content.articleURL+'?'+item.articleURL+'&userType='+userType"> {{item.name}}
                                <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                              </a>
                            </li>
                            <li v-for="(item, i) in additionalRelatedArticles" :key="i+item.name">
                              <a :href="'/'+content.articleURL+'?'+item.articleURL+'&userType='+userType"> {{item.name}}
                                <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                              </a>
                            </li>
                          </ul>
                        </div>

                      </div>
                      </div>
                    </div>
                    <div class="cell small-12" v-else>
                        <div class="text-center lds-ring-container">
                            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
      <div id="rdbothelpBox" data-toggler=".open" class="rdhelp">
        <span class="closeChat" @click="closeChat()"></span>
        <iframe sandbox="allow-modals allow-same-origin allow-scripts allow-popups allow-forms" name="webchat"
                id="webchat" class="webchat" marginwidth="0" marginheight="0" scrolling="No" frameborder="0" hspace="0"
                vspace="0" src="https://www.du.ae/servlet/duaediscovery/common/build/index.html?locale=en"></iframe>
      </div>
      <div class="survey-iframe" id="survey-form" style="display:none">
        <div class="iframe-container">
          <button class="close-button" @click="closeSurvey()" data-close aria-label="Close reveal" type="button">
            <span aria-hidden="true">&times;</span>
          </button>
          <iframe v-if="showSurvey" :src="'https://surveys.du.ae/EFM/se/38D19C331CF79DA1?source='+this.isMobile+'-du.ae-'+userType+'-Support-ThumbsUp-Article-'+this.id" ></iframe>
        </div>
      </div>

    </section>
</template>

<script>
import $ from 'jquery';
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js"
import axios from "axios";
import TroubleTicketForm from "@/components/HelpSupport/TroubleTicketForm";
import UsefulLinks from "@/components/UsefulLinksBox";

const ChatBotArticles = [
  "PROD-38881",
  "PROD-68615",
  "PROD-50257",
  "PROD-56462",
  "PROD-56459",
  "PROD-65453",
  "PROD-47334",
  "PROD-78501",
  "PROD-78502",
  "PROD-78503",
  "PROD-2678",
  "PROD-69262",
  "PROD-73379",
  "PROD-16742",
  "PROD-85545",
  "PROD-68620",
  "PROD-72951",
  "PROD-81282",
  "PROD-85198",
  "PROD-85199",
  "PROD-85200",
  "PROD-85201"
]
const quickPay = ["PROD-65453",
  "PROD-47334",
  "PROD-78501",
  "PROD-78502",
  "PROD-78501",
    ]

export default {
    name: 'ArticleDetails',
  components: {
      TroubleTicketForm,
      UsefulLinks
  },
  props: ['content'],
    data() {
        return {
          showSurvey: false,
          domain: window.location.origin,
          id: new URL(location.href).searchParams.get('artid'),
          lang: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? 'ar':'en-GB',
          version: new URL(location.href).searchParams.get('version'),
          tagName: new URL(location.href).searchParams.get('tagname'),
          contextualHelp: new URL(location.href).searchParams.get('context') === "help",
          numberOfIterations:5,
          getArticle: [],
          subTopics: [],
          breadcrumbs: [],
          getRelatedArticles: [],
          getArticleName: "",
          thumbsUpCount:0,
          isActive: 0,
          thumbsupSuccess: false,
          articleId:"",
          isMobile: window.matchMedia("only screen and (max-width: 767px)").matches ? 'mobile':'desktop',
          isRtl : document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl',
          userType : window.location.href.indexOf("business") > -1 ? 'business' : 'consumer',
          showLoader:false,
          feedbackForm: {
            isVisible: false,
            isForm: true,
          },
          feedbackReason: [],
          formIsValid: false,
          showTextArea: false,
          limit: null,
          icon:"quickPay.svg",
          chatBotAllowed: false,
          quickPayAllowed: false,
          link: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "https://myaccount.du.ae/webapp/ar/quick-pay":"https://myaccount.du.ae/webapp/en/quick-pay",
          formData:{
            _authkey_: '',
            fburl: '',
            rating:1,
            reason:'',
            description:''
          },
          embeddedArticles:null,
          additionalRelatedArticles:[],
          searchedRelatedArticles:[]
        }
    },

   async mounted() {
        if (this.id) {
          // console.log('id',this.id)
          await apiHelp.getKmuccArticle(this.id, this.lang, this.tagName).then(res => {

            const response = res.data.article
            this.getArticle = response.content
            this.getArticleName = response.name
            this.feedbackReason = response.feedback
            this.formData._authkey_ = this.feedbackReason._authkey_
            this.formData.fburl = this.feedbackReason.url
            if(res.data.related_articles && res.data.related_articles.length > 0) {
              this.getRelatedArticles = res.data.related_articles
            }
            this.limit = response.feedback.rating_desc_max_size
            if(res.data.topicBreadcrumb && res.data.topicBreadcrumb.length > 0) {
              this.breadcrumbs = res.data.topicBreadcrumb
            }
            if(res.data.embedded_articles && res.data.embedded_articles.length > 0){
              this.embeddedArticles = res.data.embedded_articles.reverse()
            }
          })
            var _this = this;
            setTimeout(function () {
              _this.responsiveTable();
            }, 500)
          }
         if (this.getArticleName) {
           apiHelp.kmuccSearch(this.getArticleName, this.lang).then(res => {
             this.searchedRelatedArticles = res.data.articles
             if (this.searchedRelatedArticles.length > 0) {
               this.relatedArtLength()
             }
           });
         }

        // if(this.getArticle) {
        //   this.embeddedArticles.forEach(embed => {
        //     console.log(embed.startIndex)
        //     this.getArticle.charAt(embed.startIndex).toUpperCase()
        //   })
        // }

        ChatBotArticles.forEach((item) => {
          if(item === this.id){
            this.chatBotAllowed = true
          }
      })
        quickPay.forEach((item) => {
        if(item === this.id){
          this.quickPayAllowed = true
        }
      })



    },
  computed:{
    instructionEn: function(){
      return this.formData.description===''?'Describe your feedback in '+this.limit+' characters': 'remaining '+this.remaining+' characters'
    },
    instructionAr: function() {
       return this.formData.description===''? 'ساعدنا في معرفة ذلك في '+this.limit+' حرف': this.remaining+' حرف متبقي'
    },
    instruction: function() {
      return this.isRtl ? this.instructionAr : this.instructionEn
    },
    remaining: function() {
      return this.limit - this.formData.description.length;
    },
    embedArticles: function(){
      let article = this.getArticle
      if(this.embeddedArticles) {
        this.embeddedArticles.forEach(item => {
          let contentString = article.substr(item.startIndex, (item.endIndex - item.startIndex)).link("/" + this.content.articleURL + "?" + item.articleURL)
          article = article.slice(0, item.startIndex) + contentString + article.slice(item.endIndex)

        })
        return article
      }
      else{
        return article
      }
    },
    breadcrumbsCheck(){
      return window.location.href.indexOf('/business') > -1 ? '' : 'business/'
    }
  },
    methods: {
      async feedbackSubmit() {
        this.showLoader = true
        try{
          const response = await axios.get(this.domain + "/kmpostfeedback?description="+this.formData.description+"&code="+this.formData.reason+"&rating="+this.formData.rating+"&_authkey_="+this.formData._authkey_+"&fburl="+this.formData.fburl.replaceAll("&", "%26"));
            console.log('form submitted successfully', response.data)
            this.feedbackForm.isForm = false;
            this.showLoader = false
        } catch (error){
            console.log('Error submmiting form', error)
        }
      },
      relatedArtLength() {
        let additionalArtArray = []
          const artCount = this.getRelatedArticles === undefined ? 0 : this.getRelatedArticles.length;
          console.log('art count', artCount)
          if(artCount > 0 && artCount < 5){
            const artLength = 5 - artCount
            additionalArtArray = this.searchedRelatedArticles.slice(1,(artLength + 1))
          }
          else if(artCount === 0){
            additionalArtArray = this.searchedRelatedArticles.slice(0,6)
          }
          additionalArtArray.forEach((item) =>  {
            if(item.name !== this.getArticleName){
              this.additionalRelatedArticles.push(item)
            }
          })
      },
      sendGA(eventCategory, eventAction, eventLabel, eventValue) {
        Analytics(eventCategory, eventAction, eventLabel, eventValue);
      },
      getArticleid() {
        return this.id;
      },
      getLocale() {
        let isArabic = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
        return isArabic ? "ar-SA" : "en-US";
      },

      closeSurvey() {
        var surveyForm = document.getElementById("survey-form");
        surveyForm.style.display = "none";

      },
      articleThumbsup(e) {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        var surveyForm = document.getElementById("survey-form");
        this.showSurvey = true;
        surveyForm.style.display = "flex";
        if (e.currentTarget.classList.contains('disabled') || e.currentTarget.classList.contains('active')) {
          return false;
        }
        e.currentTarget.classList.add('active')

        this.sendGA('help_support', 'article_detail', 'helpful-' + this.getArticleid(), 0);
        document.querySelector('.icon-unlike').classList.add('disabled');

      },
      radioSelect(e) {
        const totalRadioBtns = this.feedbackReason.codes.length
        const selectValue = e + 1
        if (totalRadioBtns == selectValue) {
          this.showTextArea = true
          this.formIsValid = false
        } else {
          this.showTextArea = false
          this.formIsValid = true
        }

      },
      detailReason(value) {
        this.formData.description = this.formData.description.substr(0, this.limit)
        this.formIsValid = false
        if (value.length > 10) {
          this.formIsValid = true
        }
      },
      articleThumbsdown(e) {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Down',
          'eventLabel': this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        if (e.currentTarget.classList.contains('active')) {
          return false;
        }
        // let varLocale = this.getLocale();
        e.currentTarget.classList.add('active')

        this.sendGA('help_support', 'article_detail', 'unhelpful-' + this.getArticleid(), 0);
        this.feedbackForm.isVisible = true;
        this.thumbsupSuccess = false;


      },
      submitFeedback() {
        this.showLoader = true
        const selectedReason = document.querySelector('input[name="radioValue"]:checked').nextElementSibling.innerText
        const feedbackDetails = document.getElementById('formfeedbackid').value



        const artFeedback = selectedReason +' - '+ feedbackDetails
        console.log(artFeedback)
        axios.post(this.feedbackReason.url, {
              'feedback': artFeedback,
            }, {
              headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
                //'Access-Control-Allow-Origin': '*'
              }
            }).then(() => {
              this.feedbackForm.isForm = false;
              this.showLoader = false
            })
                .catch((error) => {
                  console.log('something screwed up in recently viewed article: ' + error);
                })
        // this.articleSuggestMain(artFeedback);
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Send Feedback Thumbs Up',
          'eventLabel': this.id - document.getElementById('formfeedbackid').value
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }

      },
      shareThis() {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': 'copylink - ' + this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        this.sendGA('help_support', 'share', 'share_article', 0);
        navigator.clipboard.writeText(window.location.href);
      },
      sharefb() {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': 'Facebook - ' + this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        this.sendGA('help_support', 'home', 'facebook', 0);
        var url = "https://www.facebook.com/sharer/sharer.php?u=" + window.location.href;
        window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      },
      sharetw() {
        var _data = {
          'event': 'eventTracker',
          'eventCategory': 'Help & Support',
          'eventAction': 'Useful Icons – Thumbs Up',
          'eventLabel': 'Twitter - ' + this.id
        };

        if (typeof window.dataLayer != "undefined") {
          window.dataLayer.push(_data);
        }
        this.sendGA('help_support', 'home', 'twitter', 0);
        var url = "https://twitter.com/intent/tweet?url=" + window.location.href + "&text='Checkout this article!";
        window.open(url, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=300,width=600');
      },
      sharewhatsapp() {
        this.sendGA('help_support', 'home', 'whatsapp', 0);
        var url = "whatsapp://send?text=Checkout this article! " + window.location.href;
        window.open(url, '_blank');
      },
      getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
          var c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      },

      setCookie(cname, cvalue) {
        var d = new Date();
        d.setTime(d.getTime() + (5 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
      },

      responsiveTable() {
        var $table = $('table');

        if (!$table.closest('.table-resp').length) {//adding wrapper
          $table.wrap('<div class="table-wrapper"><div class="table-resp"></div></div>');
        }
      },
      showChatbot() {
        var _id = document.getElementById('rdbothelpBox');
        if ( _id.classList.contains('open') ) {
          _id.classList.remove('open');
        } else {
          _id.classList.add('open');
        }
      },
      closeChat(){
        var _id = document.getElementById('rdbothelpBox');
        _id.classList.remove('open');
      }
    }
}
</script>

<style lang="scss" scoped>
.rd-loader{
  height: 20px;
  margin-bottom:10px
}
</style>

<style lang="scss">
.topicView {
  &.bg-grey{
    background: #F8F8F8
  }
  .gutter-20{
    margin-left: -20px;
    margin-right: -20px;
    .cell{
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  .sidebar{
    .card{
      margin-bottom: 30px;
      ul{
        padding: 0 !important;
        &.article-list li a{
          padding: 15px 0;
          font-size: 15px;
        }
      }

    }
  }
  .card {
    ul:not(.article-list) {
      [dir="ltr"] & {
        padding-left: 20px;
      }

      [dir="rtl"] & {
        padding-right: 20px;
      }
    }
  }
    .grid-container {
        display: flex;
        position: relative;
    }



    &-content {
        img {
            width: auto !important;
            height: auto !important;
            max-width: 100%;
        }

        a,
        a>span {
            color: $primary-action-color !important;
            transition: .3s ease-out;

            &:hover {
                text-decoration: underline;
            }
        }

        ul, li {
            list-style: revert;
            [dir="ltr"] & {
                padding-left: revert;
            }

            [dir="rtl"] & {
               padding-right: revert;
            }
        }

        table {
            border: 0px;
            display: inline-block;
            vertical-align: top;
            margin-bottom: 20px;
            height: 100% !important;

            [dir="ltr"] & {
                margin-right: 60px;
            }

            [dir="rtl"] & {
                margin-left: 60px;
            }

            &:last-of-type {
                [dir="ltr"] & {
                    margin-right: 0px;
                }

                [dir="rtl"] & {
                    margin-left: 0px;
                }
            }

            td {
                border: 1px solid $du-border-color;
                padding: 20px 15px;
            }
        }

      .table-wrapper {
        position: relative;

        .table-resp {
          overflow-x: auto;
        }

        .table-resp {
          tbody tr {
            background: rgba(0, 169, 206, 0.05);

            &:nth-child(even) {
              background: rgba(0, 169, 206, 0.1);
            }
          }
          thead {
            background: transparent;
          }

        }

        table {
          border-collapse: inherit;
          border-spacing: 0;
          margin: 0;
          background-color: #fff;
          max-width: 80%;
          min-width: 80% !important;
          width: 100% !important;
          margin-bottom: 20px;
          @media screen and (max-width: 1023px){
            width: 100% !important;
            background: transparent;
          }

          thead, tbody {
            @media screen and (max-width: 1023px){
              border: 0;
              background: transparent;
            }
          }

          thead {
            th {
              border-bottom: 1px solid $du-border-color;
              font-size: 16px;
              font-family: $du-font-secondary-bold;
              padding: 1rem 10px;
              vertical-align: top;
              color: #777;
              @media screen and (min-width: 1024px){
                padding: 1rem 10px;
              }
              &:first-of-type {
                @media screen and (min-width: 1024px){
                  [dir="ltr"] & {
                    padding-left: 1.5rem;
                  }
                  [dir="rtl"] & {
                    padding-right: 1.5rem;
                  }
                }
              }
              &:last-of-type {
                @media screen and (min-width: 1024px){
                  [dir="ltr"] & {
                    padding-left: 1.5rem;
                  }
                  [dir="rtl"] & {
                    padding-right: 1.5rem;
                  }
                }
              }
            }
          }

          tbody {
            tr {
              &:first-of-type {
                td, th {
                  border-top: 0;
                }
              }
            }
            th {
              color: #777;
              font-family: $du-font-secondary-bold;
            }
            td, th {
              border-top: 1px solid $du-border-color;
              font-size: 16px;
              font-family: $du-font-secondary-regular;
              padding: 1rem 10px;
              border-left: 0;
              border-right: 0;
              border-bottom: 0;
              width: auto !important;
              [dir="ltr"] & {
                text-align: left;
              }
              [dir="rtl"] & {
                text-align: right;
              }
              vertical-align: top;
              min-width: 100px;

              &:first-child {
                [dir="ltr"] & {
                  padding-left: 1.5rem;
                }
                [dir="rtl"] & {
                  padding-right: 1.5rem;
                }
              }

              &:last-child {
                [dir="ltr"] & {
                  padding-left: 1.5rem;
                }
                [dir="rtl"] & {
                  padding-right: 1.5rem;
                }
              }

            }
          }


        }


      }
    }

  .feedback {
    //background: #f7f7f7;
    padding: 0;
    max-width: 1266px;
    margin: 24px auto 0;
    .grid-container {
    }
    .chat-card{
      @media only screen and (max-width: $phone-max-size) {
        padding: 0 !important;
        margin-top:20px;
      }
    }
    .feedback-inner {
      border-bottom: #ddd solid 1px;
      width: 100%;
      padding: 1.25rem;
      @media only screen and (min-width: $phone-max-size) {
        padding: 1.5rem;
      }
    }
    .headline5 {
      margin-bottom: 10px;
    }
    .action-grid {
      background: #eee;
      border-radius: 6px;
      padding: 20px;
    }
    .success-confirm {
      padding: 1.25rem;
      @media only screen and (min-width: $phone-max-size) {
        padding: 1.5rem;
      }

      .contact {
        display: block;
        position: relative;
        padding: 15px 20px;
        padding-right: 50px;
        border: #00A9CE solid 1px;
        border-radius: 12px;
        background: #fff;
        font-family: $du-font-secondary-regular;
        font-size: 1rem;
        line-height: 1em;
        color: #00A9CE;
      }
    }

    .ctas {
      display: flex;
      align-items: center;
      span{
        margin-right: 10px;
        [dir="rtl"] & {
          margin-right: 0;
          margin-left:10px;
        }
      }
      a {
        display: inline-block;
        vertical-align: top;
        padding: 0;
        &.disabled img {
          opacity: .4;
        }

      }
      img {
        max-width: 40px;
        margin-right: 10px;
        border-radius: 50%;
        [dir="rtl"] & {
          margin-right: 0;
          margin-left: 10px;
        }
        small{
          display: block;
          text-align: center;
          padding-right: 10px;
          margin-top: 3px;
          color: #333;
        }
      }
    }

    .feedback-form {
      padding: 30px 0;
      min-width: 400px;
      p.body1 {
        font-size: 1rem;
        margin-bottom: 10px;
      }
      .group {
        position: relative;
        border: 0;
        padding: 0;
        margin: 20px 0 0;
        textarea {
          border: 1px solid #ddd;
          border-radius: 4px;
          width: 100%;
          min-height: 100px;
          padding: .5rem;
          :focus{
            border-color: #00A9CE
          }
        }
        label {
          color: #333;
          font-size: 18px;
          position: absolute;
          top: 9px;
          left: 10px;
          [dir="rtl"] & {
            left: auto;
            right: 10px;
          }
          transition: all .2s ease-in;
        }
        textarea:focus ~ label, textarea:valid ~ label {
          top: -20px;
          font-size: 14px;
          color: #09c;
          left: 0;
          [dir="rtl"] & {
            left: auto;
            right: 0;
          }
        }
        .btn {
          border: 0;
          cursor: pointer;
          &.disabled {
            opacity: 0.4;
          }
        }

      }
    }
    .thankyou-msg {
      padding-top: 20px;
      border-top: 1px solid #ddd;
      margin-top: 20px;
    }
  }
  //.exclude-disable{
  //  color: $primary-action-color !important;
  //  cursor: pointer !important;
  //}
  .survey-iframe{
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    justify-content:center;
    align-items: center;
    background: rgba(0,0,0,0.8);
    z-index: 9999;
    .iframe-container{
      //max-width:900px;
      //height: auto;
      position: relative;
      overflow: hidden;
      max-width: 900px;
      width: 100%;
      height: auto;
      min-height: 550px;
      //padding-top: 56.25%; /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */

      button.close-button {
        top: 0;
        position: absolute;
        right: 0;
        z-index: 9;
        span {
          margin: 6px;
          font-size: 33px;
        }
      }
      iframe{
        //width:900px;
        //height: 520px;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        border: 0;
        border-radius: 10px;
        //@media only screen and (max-width: $phone-max-size) {
        //  width:360px;
        //  height: 520px;
        //}
      }
    }


  }

}
</style>