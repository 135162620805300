<template>
<div>
    <div class="body">
      <div class="grid-container narrow">
        <div class="card">
          <ul>
            <li v-for="(item,i) in helpData" :key="i">
              <a :href="language ? 'https://www.du.ae/ar/support-articledetail?artid='+item.id+'&lang=ar-SA':'https://www.du.ae/support-articledetail?artid='+item.id+'&lang=en-USw'">{{item.title}}
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.29006 0.709778C0.900059 1.09978 0.900059 1.72978 1.29006 2.11978L5.17006 5.99978L1.29006 9.87978C0.900059 10.2698 0.900059 10.8998 1.29006 11.2898C1.68006 11.6798 2.31006 11.6798 2.70006 11.2898L7.29006 6.69978C7.68006 6.30978 7.68006 5.67978 7.29006 5.28978L2.70006 0.699778C2.32006 0.319778 1.68006 0.319778 1.29006 0.709778Z" fill="#C724B1"/>
                </svg>

              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
</div>
</template>
<script>

import apiHelp from '../api-help'
export default {
    name: 'ContextualHelp',
    data() {
        return {
            helpData: "",
            language: document.getElementsByTagName('html')[0].getAttribute("dir") === 'rtl'
        }
    },    
    mounted() {
        apiHelp.getContextualHelp().then(res => {
          // const parsed = JSON.parse(res)
          this.helpData = res
        });

    },
    components: {

    }
};
</script>
<style lang="scss" scoped>
.body{
  background: #eee;
  height: 100vh;
  overflow: auto;
  padding-top: 20px;
}
.card{
  width:100%;
  max-width: 1024px;
  margin: 0 auto;
  ul{
    margin-bottom: 0;
    li {
      a{
        display: flex;
        align-items: center;
        color: #333;
        justify-content: space-between;
        padding: 15px 10px;
        svg{
          margin-left: 10px;
          flex-shrink:0;

          [dir="rtl"] & {
            margin-right: 10px;
            margin-left: 0;
            transform: scaleX(-1);
          }
        }

      }
      + li{
        border-top: 1px solid #eee;
      }
    }
  }


}
</style>