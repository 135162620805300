var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[!_vm.isMobile ? 'grid-container no-padding': ''],style:([_vm.isMobile ? {'margin-top': '20px'}: null])},[_c('div',{staticClass:"homeinternet homeinternet-content",style:({background: _vm.homeIntenet.background})},[(_vm.isMobile)?_c('div',{staticClass:"homeinternet-content__preposition-usp"},_vm._l((_vm.homeIntenet.features),function(feature,i){return _c('div',{key:i,staticClass:"homeinternet-content__preposition-usp__feature"},[_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":feature.svg.width,"height":feature.svg.height}}),_c('span',{staticClass:"homeinternet-content__preposition-usp__feature-title",style:({ color: _vm.homeIntenet.textColor })},[_vm._v(_vm._s(feature.title))])])}),0):_vm._e(),_c('div',{staticClass:"homeinternet-content__image"},[_c('img',{style:([
        _vm.isMobile
          ? {  height: '313px', mixBlendMode: 'multiply' }
          : {  height: '400px', mixBlendMode: 'multiply' },
      ]),attrs:{"src":_vm.path+_vm.homeIntenet.image,"alt":_vm.homeIntenet.title}})]),_c('div',{staticClass:"homeinternet-content__preposition"},[(!_vm.isMobile)?_c('div',{staticClass:"homeinternet-content__preposition-usp"},_vm._l((_vm.homeIntenet.features),function(feature,i){return _c('div',{key:i,staticClass:"homeinternet-content__preposition-usp__feature"},[_c('img',{attrs:{"src":_vm.path+feature.svg.name,"alt":feature.svg.name,"width":feature.svg.width,"height":feature.svg.height}}),_c('span',{staticClass:"homeinternet-content__preposition-usp__feature-title",style:({ color: _vm.homeIntenet.textColor })},[_vm._v(_vm._s(feature.title))])])}),0):_vm._e(),_c('div',{staticClass:"homeinternet-content__preposition-title",style:({ color: _vm.homeIntenet.textColor })},[_vm._v(" "+_vm._s(_vm.homeIntenet.title)+" ")]),_c('div',{staticClass:"homeinternet-content__preposition-subtitle",style:({ color: _vm.homeIntenet.textColor })},[_vm._v(" "+_vm._s(_vm.homeIntenet.subtitle)+" ")]),_c('div',{staticClass:"homeinternet-content__preposition-frame"},[_c('div',{staticClass:"input-container"},[(_vm.homeIntenet.isTextFieldPresent)?_c('input',{ref:"location",staticClass:"homeinternet-content__preposition-frame__textField",style:([_vm.isArabic ? {paddingRight: '40px'} : {paddingLeft: '40px'}]),attrs:{"type":"text","id":"location-placeholder","placeholder":_vm.homeIntenet.textFieldPlaceHolder}}):_vm._e(),(_vm.homeIntenet.textFieldIcon)?[_c('span',{staticClass:"icon",style:([{
                backgroundImage:
                  'url(' + _vm.path+_vm.homeIntenet.textFieldIcon.name + ')',
              },
              _vm.isArabic ? {right: '10px'} : {left: '10px'}])})]:_vm._e()],2),(_vm.homeIntenet.buttonText)?_c('a',{staticClass:"homeinternet-content__preposition-frame__button",class:{'btn':true ,'white': _vm.homeIntenet.buttonColor === 'white' ? true : false},attrs:{"href":_vm.getURL()},on:{"click":function($event){return _vm.sendGA(
              _vm.allDevice.ga.eventCategory,
              _vm.allDevice.ga.eventAction,
              _vm.allDevice.ga.eventLabel,
              _vm.allDevice.ga.eventValue
            )}}},[_c('span',{staticClass:"homeinternet-content__preposition-frame__button-text",style:({ color: _vm.homeIntenet.buttonTextColor })},[_vm._v(_vm._s(_vm.homeIntenet.buttonText))])]):_vm._e()])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }