<template>
    <div class="body">
        <BannerSearch v-if="!contextualHelp" />
        <ArticleDetails  :content="contentMisc.helpTopics"/>
        <Footer v-if="!contextualHelp"/>
        <Gradients v-if="!contextualHelp"/>
    </div>
</template>
<script>
import BannerSearch from '../components/HelpSupport/BannerSearch';
import ArticleDetails from '../components/HelpSupport/ArticleDetails';
import Footer from '../components/Footer';
import Gradients from '../components/Gradients';

import apiHelp from '../api-help'

export default {
    name: 'SupportArticleDetail',
    data() {
        return {
            contentMisc: [],
            contextualHelp: new URL(location.href).searchParams.get('context') === "help"
        }
    },
    mounted() {
         console.log(this.contextualHelp)
        apiHelp.contentMisc().then(res => { this.contentMisc = res
        });

    },
    components: {
        BannerSearch,
        ArticleDetails,
        Footer,
        Gradients,
    }
};
</script>