<template>
  <div class="grid-container narrow margin-top-30 margin-bottom-30">
    <div class="grid-x">
      <div class="cell small-12">
<!--        <div class="card">-->
<!--          <h3>Ask questions</h3>-->
<!--          <input type="text" class="form-control" v-model.lazy="message" @change="userInputHandler()" placeholder="Ask me anything" />-->
<!--        </div>-->
        <div class="mb30">
          <h3 class="headline3 mb30">{{searchTreeData[0].name}}</h3>
          <div class="headline5 mb10">{{searchTreeData[0].dtree_script.topicHeading}}</div>
          <ul v-for="(data,i) in searchTreeData" :key="i">
            <li v-for="(btn,index) in data.dtree_script.components" :key="index" class="mb20">
              <input type="radio" :name="data.dtree_script.name"
                     :id="'radio-'+btn.component"
                     @change="buttonHandler(btn.component, data.dtree_script.migratableReference, data.dtree_navigate_url, data.dtree_script.initial)"/>
              <label :for="'radio-'+btn.component">{{btn.label}}</label>
            </li>
          </ul>
        </div>

        <div v-for="(master,i) in responseData" :key="i" :id="master.parent_component+'-'+master.parent_id.replace(/\s/g, '')" class="mt30 card">
          <h2 class="headline4">{{master.dtree_script.topicHeading}}</h2>
          <div class="body-1" v-for="(data,i) in master.dtree_script.components" :key="i">
            <div v-if="data.presentation == 'PageText'" >
              <span>
              {{data.label}}
              <span v-html="data.html"></span>
              </span>
            </div>
            <div class="grid-x" v-if="data.presentation == 'Dropdown'">
              <strong class="cell mb5">{{data.label}}</strong>
              <fieldset class="cell small-12 medium-3">
                <select>
                  <option v-for="(value,i) in data.values" :key="i">{{value.label}}</option>
                </select>
              </fieldset>
            </div>
            <div class="grid-x" v-if="data.presentation == 'Content'">
              <strong class="cell mb5">{{data.values[0].title}}</strong>
              <p v-html="data.values[0].data.content"></p>
            </div>
            <div v-else>
              <fieldset v-if="data.label === 'Exit'">
                <button class="btn btn-sm mt30" type="button" @click="responseData = []">{{data.label}}</button>
              </fieldset>
              <fieldset class="mt20" v-else>
                <input type="radio" v-if="data.label" :name="master.dtree_script.name" :id="'subRadio-'+master.identifier+data.component" :data-component="data.component" :data-migRef="master.dtree_script.migratableReference" :data-navUrl="master.dtree_navigate_url" @change="buttonHandler(data.component, master.dtree_script.migratableReference, master.dtree_navigate_url)"/>
                <label :for="'subRadio-'+master.identifier+data.component">{{data.label}}</label>
              </fieldset>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Analytics } from "@/analytics.js";
import apiHelp from "@/api-help";
// import axios from "axios";

export default {
  name: 'DecisionTree',
  data() {
    return {
      message:null,
      searchTreeData:[],
      buttonId:"",
      responseData:[],
      mRefArray: [],
      decisionTree : [],
      searchText: new URL(location.href).searchParams.get('query'),
      lang: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? 'ar':'en'
    }
  },
  async mounted() {
    if(this.searchText){
      console.log('search text', this.searchText)
      await apiHelp.kmuccSearchTree(this.searchText, this.lang).then(res => {
        this.searchTreeData = res.data.scripts
        console.log('search tree data',this.searchTreeData)
      });
    }
  },
  methods: {
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
    async buttonHandler(btnComponent,mRef,navUrl,initial) {
      console.log('ini',initial)
      if(initial == true){
        this.decisionTree = []
      }
      const refStr = mRef.replace(/\s/g, '')
      const ref = btnComponent+'-'+refStr
      const finalRef = ref.toString()
      await apiHelp.kmuccTreeNavigate(btnComponent, mRef, navUrl,this.lang).then(res => {
        this.decisionTree.push(res.data)

        this.responseData = this.uniqueEntries(this.decisionTree, 'identifier')

        setTimeout(() => {
          document.getElementById(finalRef).scrollIntoView({
           behavior: 'smooth',
          });
        },500)
      })
    },
    uniqueEntries(arr, prop) {
      return arr.filter((obj, index, self) =>
          index === self.findIndex((o) => o[prop] === obj[prop])
      );
    },
  }
}
</script>
<style lang="scss">
.body-1 {
  font-family: du-font-secondary-regular;
  *{
    font-family: du-font-secondary-regular !important;
  }
}
select{
  height: 44px;
  border-radius:10px;

}
</style>

