<template>
    <div class="body">
<!--        <div v-if="isOldFlow">
            <Spotlight/>
            <QuickLinks/>
            <ShopCards/>
            <PlansSuggestion/>
            <HelpSection :isOldFlow="isOldFlow"/>
            &lt;!&ndash;        <Banner />&ndash;&gt;
            <PositiveReview/>
            <CustomerLinks/>
            <AddLife/>
            <Footer/>
            <Gradients/>
        </div>-->

        <div class="grey-background">
            <Spotlight/>
            <BestOffers/>
            <Waypoint :tag="'div'" @change="onChange">
                <div v-if="load">
                    <LatestDevice/>
                    <AllDevice/>
                    <SpecialOffer/>
                    <HomeInternet/>
                    <NewPackage/>
                    <NewSim/>
                    <NewQuickLink/>
                    <DuApp/>
                    <ExtraFeature/>
                    <HelpSection/>
                </div>
            </Waypoint>
            <Footer/>
            <Gradients/>
        </div>
    </div>
</template>
<script>
import Spotlight from "@/components/Homepage/Spotlight";
// import QuickLinks from "@/components/Homepage/QuickLinks";
// import ShopCards from "@/components/Homepage/ShopCards";
// import PlansSuggestion from "@/components/Homepage/PlansSuggestion";
//import Banner from '@/components/Homepage/Banner';
// import PositiveReview from '@/components/Homepage/PositiveReview';
// import CustomerLinks from "@/components/Homepage/CustomerLinks";
// import AddLife from "@/components/Homepage/AddLife";
import BestOffers from "@/components/Homepage/BestOffers";
import LatestDevice from "@/components/Homepage/LatestDevice";
import AllDevice from "@/components/Homepage/AllDevice";
import SpecialOffer from "@/components/Homepage/SpecialOffer";
import HomeInternet from "@/components/Homepage/HomeInternet";
import NewPackage from "@/components/Homepage/NewPackage";
import NewSim from "@/components/Homepage/NewSim";
import ExtraFeature from "@/components/Homepage/ExtraFeature";
import DuApp from "@/components/Homepage/DuApp.vue";
import HelpSection from "@/components/Homepage/HelpSection";
import NewQuickLink from "@/components/Homepage/NewQuickLink";
import Footer from "@/components/Footer";
import Gradients from "@/components/Gradients";
import { Waypoint } from "vue-waypoint";

export default {
    name: "App",
    components: {
        Spotlight,
        // QuickLinks,
        // ShopCards,
        // PlansSuggestion,
        // Banner,
        // PositiveReview,
        // CustomerLinks,
        // AddLife,
        BestOffers,
        LatestDevice,
        AllDevice,
        SpecialOffer,
        HomeInternet,
        NewPackage,
        NewSim,
        DuApp,
        ExtraFeature,
        HelpSection,
        NewQuickLink,
        Footer,
        Gradients,
        Waypoint
    },
    setup() {
        /*const onChange = (waypointState) => {
            // Going can be:
            // IN
            // OUT
            console.log(waypointState.going);
            if(waypointState.going == "IN" && waypointState.direction == "UP") {
                this.load = true
            }
            // Direction can be:
            // UP
            // DOWN
            // LEFT
            // RIGHT
            console.log(waypointState.direction);
        };

        return { onChange };*/
    },
    data: () => ({
        path: process.env.VUE_APP_PATH,
        load: false
        // isOldFlow: false,
    }),
    mounted() {
        /*this.isOldFlow = this.getParameterByName("oldPage")
                ? this.getParameterByName("oldPage") === "true"
                : false;*/
    },
    methods: {
        async loadModules({ going }) {
            if (going === this.$waypointMap.GOING_IN && !this.loading) {
                this.loading = true
            }
        },

        onChange (waypointState) {
            // Going can be:
            // IN
            // OUT
            console.log(waypointState.going);

            let height = window.innerHeight;
            if(waypointState.going == "IN" && height >= 1200) {
                this.load = true
            }
            if(waypointState.going == "IN" && waypointState.direction == "UP") {
                this.load = true
            }

            // Direction can be:
            // UP
            // DOWN
            // LEFT
            // RIGHT
            console.log(waypointState.direction);
        }
        /*getParameterByName(name, url) {
            if (!url) url = window.location.href;
            name = name.replace(/[[]]/g, "$&");
            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                    results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return "";
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        },*/
    },
};
</script>
<style lang="scss">
@import "@/styles/style.scss";

// .body{
//     background: yellow;
//   }
.grey-background {
    background: #f8f8f8;
}
</style>
