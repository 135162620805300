<template>
  <div
    :class="[!isMobile ? 'grid-container no-padding' : '']"
    :style="[
      isMobile ? { 'margin-top': '20px' } : null,
    ]"
  >
    <div class="allDevice-content allDevice" :style="{ background: allDevice.background }">
      <div class="allDevice-usp">
        <div
          class="allDevice-usp--features"
          v-for="(feature, i) in allDevice.features"
          :key="i"
        >
          <img
            :src="path+feature.svg.name"
            :alt="feature.svg.name"
            :width="feature.svg.width"
            :height="feature.svg.height"
          />
          <div
            class="allDevice-usp--features_title"
            :style="{ color: allDevice.color }"
          >
            {{ feature.title }}
          </div>
        </div>
      </div>

      <!-- <div class="full-mobile"> -->
      <!-- <div class="grid-x"> -->
      <div class="cell">
        <slick
          id="slick-alldevice"
          :options="slickOptionsCards"
          v-if="allDevice.cards"
        >
          <div
            class="allDevice-devices__device"
            style="cursor: pointer;"
            v-for="(device, i) in allDevice.cards"
            :key="i + 'key'"
            @click="redirectToDevice(device.url)"
          >
            <div class="allDevice-devices__device-title">
              <span>{{ device.title }}</span>
              <span>{{ device.subtitle }}</span>
            </div>
            <div class="allDevice-devices__device-image" v-if="device.image">
              <img :src="path+device.image" :alt="device.title" />
            </div>

            <div class="allDevice-devices__device-price">
              <span>{{ device.startingText }}</span>
              <span v-if="!isArabic"
                >{{ device.currency }} {{ device.price }}
                {{ device.priceDetailsMode }}</span
              >
              <span v-if="isArabic">
                {{ device.price }} {{ device.currency }}
                {{ device.priceDetailsMode }}
              </span>
            </div>
          </div>
        </slick>
      </div>
      <!-- </div>-->
      <!-- </div>  -->

      <a
        :href="allDevice.buttonURL"
        class="allDevice-button"
        v-if="allDevice.buttonText"
        :class="{'btn':true ,'white': allDevice.buttonColor === 'white' ? true : false}"
        @click="
          sendGA(
            allDevice.ga.eventCategory,
            allDevice.ga.eventAction,
            allDevice.ga.eventLabel,
            allDevice.ga.eventValue
          )
        "
      >
        <span
          :style="{ color: allDevice.buttonTextColor }"
          class="allDevice-button-text"
        >
          {{ allDevice.buttonText }}
        </span>
      </a>
    </div>
  </div>
</template>

<script>
import api from "@/api";
import { Analytics } from "@/analytics.js";
import Slick from "vue-slick";

export default {
  name: "AllDevice",
    props:{
        path: {
            type: String,
            default: process.env.VUE_APP_PATH
        }
    },
  components: { Slick },
  data: () => ({
    allDevice: [],
    isMobile: window.matchMedia("only screen and (max-width: 767px)").matches,
    isArabic:
      document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl",
    slickOptionsCards: {
      slidesToShow: 4.2,
      arrows: false,
      slidesToScroll: 4,
      centerMode: false,
      dots: true,
      infinite: false,
      cssEase: "linear",
      rtl:
        document.getElementsByTagName("html")[0].getAttribute("dir") === "rtl"
          ? true
          : false,
      responsive: [
        {
          breakpoint: 1057,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 660,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    },
  }),
  mounted() {
    api.allDevice().then((response) => {
      this.allDevice = response;
    });

    this.$nextTick(() => {
      window.addEventListener("resize", this.checkIsMobile, { passive: true });
    });
  },
  methods: {
    checkIsMobile() {
      this.isMobile = window.matchMedia(
        "only screen and (max-width: 767px)"
      ).matches;
    },
    sendGA(eventCategory, eventAction, eventLabel, eventValue) {
      Analytics(eventCategory, eventAction, eventLabel, eventValue);
    },
    redirectToDevice(url) {
      window.location.href = url;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkIsMobile);
  },
};
</script>

<style scoped lang="scss">
.no-padding {
  padding: 0px !important;
}
.allDevice {
  padding: 72px 0;

  @media only screen and (max-width: $phone-max-size) {
    padding: 24px 20px 32px 20px;
  }

  &-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;

    @media only screen and (max-width: $phone-max-size) {
      gap: 24px;
      align-items: flex-start;
    }

    &::v-deep .slick-track {
      display: flex;
      // gap: 24px;
      align-items: flex-start;
      justify-content: center;
      margin-bottom: 32px;

      &:first-child {
        gap: 24px;
      }

      @media only screen and (max-width: $phone-max-size) {
        margin-bottom: 0px;
      }

      .slick-slide {
        display: flex;
        align-items: center;
        justify-content: center;
        transition: $transition;
        background-color: $white;
        overflow: hidden;
        box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);
        border-radius: 12px;

        @media only screen and (min-width: $phone-max-size) {
          &:first-of-type {
            [dir="ltr"] & {
              margin-left: 0;
            }

            [dir="rtl"] & {
              margin-right: 0;
            }
          }

          &:last-of-type {
            [dir="ltr"] & {
              margin-right: 0;
            }

            [dir="rtl"] & {
              margin-left: 0;
            }
          }
        }

        &:hover {
          @media only screen and (min-width: $laptop-min-size) {
            transform: translateY(-5px);
          }
        }

        @media only screen and (max-width: $phone-max-size) {
          margin-bottom: 24px;
        }

        & > div {
          //height: 100%;
          width: 100%;
          border-radius: 12px;
        }
      }
    }

    .slick-list {
      @media only screen and (min-width: $laptop-min-size) {
        padding: 0 !important;
        overflow: visible;
      }
    }
  }

  &-usp {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 192px;
    width: 100%;
    flex-wrap: wrap;

    @media only screen and (max-width: $phone-max-size) {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 14px;
      padding: 0px;
    }

    &--features {
      display: flex;
      align-items: center;
      gap: 8px;

      @media only screen and (max-width: $phone-max-size) {
        align-items: flex-start;
        gap: 8px;
        // flex-basis: 47%;
        // flex-grow: 0;
      }

      &_title {
        font-family: $du-font-secondary-regular;
        font-size: 17px;
        line-height: 24px;

        [dir="rtl"] & {
          font-family: $du-font-secondary-regular-ar;
        }
      }
    }
  }

  &-devices {
    display: flex;
    width: 100%;
    height: 387px;
    padding-left: 0;
    align-items: flex-start;
    gap: 24px;

    &__device {
      display: flex !important;
      // width: 276px !important;
      padding: 24px;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      flex-shrink: 0;
      border-radius: 12px;
      box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.1);

      &-title {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span:first-child {
          font-family: $du-font-secondary-bold;
          font-size: 24px;
          line-height: 32px;

          [dir="rtl"] & {
            font-family: $du-font-secondary-bold-ar;
          }
        }
      }

      &-price {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        span:first-child {
          font-size: 15px;
          line-height: 22px;
          font-family: $du-font-secondary-regular;

          [dir="rtl"] & {
            font-family: $du-font-secondary-regular-ar;
          }
        }

        span:not(:first-child) {
          font-family: $du-font-secondary-bold;
          font-size: 24px;
          line-height: 32px;

          [dir="rtl"] & {
            font-family: $du-font-secondary-bold-ar;
          }
        }
      }
    }
  }

  &-button {
    display: flex;
    width: 239px;
    padding: 16px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 12px;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);

    @media only screen and (max-width: $phone-max-size) {
      width: 100%;
    }

    &-text {
      font-family: $du-font-secondary-medium;
      font-size: 20px;
      line-height: 24px;

      [dir="rtl"] & {
        font-family: $du-font-secondary-medium-ar;
      }
    }
  }
}

.gradient {
  background: linear-gradient(60deg, #753bbd 0%, #c724b1 60%);
}
</style>
