<template>
    <section class="topicView grey-bg">
        <div class="topicView-inner">
          <div class="grid-container narrow">
            <div class="grid-x">
              <div class="cell small-12">
                <div class="breadcrumb">
                  <ul class="breadcrumb-list" v-if="content">
                    <li class="breadcrumb-item"><a :href="'content.helpURL?userType='+ userType" class="link du-magenta">{{this.helpText}}</a></li>
                    <li class="breadcrumb-item"><a>{{this.searchText}}</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
            <div class="grid-container narrow">
                <div class="grid-x">
                    <div class="cell small-12">
                        <div class="card">
                            <template v-if="getSearchResult && content">
<div v-if="getSearchResult
.length > 0">
                                 <paginate name="paginate-items" :list="getSearchResult" :per="5" class="article-list">
                                    <li v-for="(article,i) in paginated('paginate-items')" :key="i+'art'">
                                        <a v-if="lang === 'ar'" :href="article.type === 'Article'|| article.type === 'FAQ'? 'ar/support-articledetail?userType='+ userType +'&'+article.articleURL : 'ar/decisionTree?userType='+ userType +'&query='+article.name" class="link black" @click="sendGA('Help & Support', 'Article', article.name, 0);">
                                            {{article.name}}
                                          <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                                        </a>
                                      <a v-else :href="article.type === 'Article' || article.type === 'FAQ'? 'support-articledetail?userType='+ userType +'&'+article.articleURL : 'decisionTree?userType='+ userType +'&query='+article.name" class="link black" @click="sendGA('Help & Support', 'Article', article.name, 0);">
                                        {{article.name}}
                                        <img class="chevron" src="https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg" />
                                      </a>
                                    </li>
                                 </paginate>
                                 <paginate-links
                                     for="paginate-items"
                                     class="pagination justify-content-center"
                                     :limit="3"
                                     :show-step-links="true"
                                     :step-links="{
                                        next: '>',
                                        prev: '<',
                                      }"
                                     :classes="{
                                        'ul.paginate-links > li': 'page-item',
                                        'ul.paginate-links > li > a': 'page-link',
                                      }"
                                 ></paginate-links>
</div>
                                <div class="text-center" v-else-if="getSearchResult.correctedQuery">
                                    <img class="topicView-notFound" src="../../assets/not-found.png">
                                    <p class="subtitle2">{{content.didyouMean}} 
                                        <a :href="content.searchResultURL+getSearchResult.correctedQuery" class="link magenta">
                                            {{getSearchResult.correctedQuery}}
                                        </a> 
                                        {{content.didyouMean2}}
                                    </p>
                                </div>
                                <div  class="text-center" v-else>
                                    <img class="topicView-notFound" src="../../assets/not-found.png">
                                    <p class="subtitle2" v-html="content.notFound"></p>
                                    <p v-html="content.notFoundAlternative"></p>                                         
                                </div>
                            </template>
                            <template v-else>
                                 <div class="text-center lds-ring-container">
                                    <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";
import Vue from 'vue'
import VuePaginate from 'vue-paginate'
Vue.use(VuePaginate)
export default {
    name: 'HelpResultList',
    props: ['content'],
    data() {
        return {
            paginate: ["paginate-items"],
            searchText: new URL(location.href).searchParams.get('q'),
            helpText: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? "المساعدة" : "Help",
            getSearchResult: [],
            lang: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? 'ar':'en',
            userType : window.location.href.indexOf("business") > -1 ? 'business' : 'consumer'
        }
    },
    mounted() {
        if (this.searchText) {
            apiHelp.kmuccSearch(this.searchText, this.lang).then(res => {
              this.getSearchResult = res.data.articles
            });
        }
    },
    methods: {
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        }
    }
}
</script>
<style lang="scss">

.topicView {
  &.grey-bg{
    background: #F8F8F8
  }
  .card {
    background: #FFFFFF;
    border: 1px solid #DDDDDD;
    border-radius: 12px;
    padding: 24px;

    ul.article-list {
      li {
        &:first-child {
          a {
            padding-top: 0;
          }
        }

        &:last-child {
          a {
            padding-bottom: 0;
          }
        }

        & + li {
          border-top: 1px solid #DDDDDD;

        }

        a {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 24px 0;
          color: black;

          &:hover {
            color: #C724B1;
          }
        }
      }
    }
  }
  ul.pagination {
    display: flex;
    justify-content: center;
    li{
      border-top:none !important;
      &.active a{
        background: #C724B1;
        color: white;
        &:hover{
          color:white;
        }
      }
      a {
        margin: 0 5px;
        background: #eee;
        border-radius: 50px;
        width: 30px;
        border:1px solid #eee;
        height: 30px;
        display: block;
        text-align: center;
        line-height: 30px;
        font-size: 14px;
        cursor: pointer;
        &:hover{
          color: #C724B1;
          border-color: #C724B1
        }
      }
    }

  }

    .grid-container {
        display: flex;
        position: relative;
    }

    .link.black.underline {
      display: block;
      text-decoration: none;
      padding: 10px 0;
    }

    &-notFound {
        width: 150px;
        height: 150px;
    }


}
</style>