var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"topicView grey-bg"},[_c('div',{staticClass:"topicView-inner"},[_c('div',{staticClass:"grid-container narrow"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell small-12"},[_c('div',{staticClass:"breadcrumb"},[(_vm.content)?_c('ul',{staticClass:"breadcrumb-list"},[_c('li',{staticClass:"breadcrumb-item"},[_c('a',{staticClass:"link du-magenta",attrs:{"href":'content.helpURL?userType='+ _vm.userType}},[_vm._v(_vm._s(this.helpText))])]),_c('li',{staticClass:"breadcrumb-item"},[_c('a',[_vm._v(_vm._s(this.searchText))])])]):_vm._e()])])])]),_c('div',{staticClass:"grid-container narrow"},[_c('div',{staticClass:"grid-x"},[_c('div',{staticClass:"cell small-12"},[_c('div',{staticClass:"card"},[(_vm.getSearchResult && _vm.content)?[(_vm.getSearchResult
.length > 0)?_c('div',[_c('paginate',{staticClass:"article-list",attrs:{"name":"paginate-items","list":_vm.getSearchResult,"per":5}},_vm._l((_vm.paginated('paginate-items')),function(article,i){return _c('li',{key:i+'art'},[(_vm.lang === 'ar')?_c('a',{staticClass:"link black",attrs:{"href":article.type === 'Article'|| article.type === 'FAQ'? 'ar/support-articledetail?userType='+ _vm.userType +'&'+article.articleURL : 'ar/decisionTree?userType='+ _vm.userType +'&query='+article.name},on:{"click":function($event){return _vm.sendGA('Help & Support', 'Article', article.name, 0);}}},[_vm._v(" "+_vm._s(article.name)+" "),_c('img',{staticClass:"chevron",attrs:{"src":"https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg"}})]):_c('a',{staticClass:"link black",attrs:{"href":article.type === 'Article' || article.type === 'FAQ'? 'support-articledetail?userType='+ _vm.userType +'&'+article.articleURL : 'decisionTree?userType='+ _vm.userType +'&query='+article.name},on:{"click":function($event){return _vm.sendGA('Help & Support', 'Article', article.name, 0);}}},[_vm._v(" "+_vm._s(article.name)+" "),_c('img',{staticClass:"chevron",attrs:{"src":"https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/img/chevron-right.svg"}})])])}),0),_c('paginate-links',{staticClass:"pagination justify-content-center",attrs:{"for":"paginate-items","limit":3,"show-step-links":true,"step-links":{
                                        next: '>',
                                        prev: '<',
                                      },"classes":{
                                        'ul.paginate-links > li': 'page-item',
                                        'ul.paginate-links > li > a': 'page-link',
                                      }}})],1):(_vm.getSearchResult.correctedQuery)?_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"topicView-notFound",attrs:{"src":require("../../assets/not-found.png")}}),_c('p',{staticClass:"subtitle2"},[_vm._v(_vm._s(_vm.content.didyouMean)+" "),_c('a',{staticClass:"link magenta",attrs:{"href":_vm.content.searchResultURL+_vm.getSearchResult.correctedQuery}},[_vm._v(" "+_vm._s(_vm.getSearchResult.correctedQuery)+" ")]),_vm._v(" "+_vm._s(_vm.content.didyouMean2)+" ")])]):_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"topicView-notFound",attrs:{"src":require("../../assets/not-found.png")}}),_c('p',{staticClass:"subtitle2",domProps:{"innerHTML":_vm._s(_vm.content.notFound)}}),_c('p',{domProps:{"innerHTML":_vm._s(_vm.content.notFoundAlternative)}})])]:[_vm._m(0)]],2)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text-center lds-ring-container"},[_c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])])
}]

export { render, staticRenderFns }