<template>
    <div class="body">
        <BannerSearch />
        <DecisionTree />
        <Footer />
    </div>
</template>
<script>
import BannerSearch from '../components/HelpSupport/BannerSearch';
import DecisionTree from '../components/HelpSupport/DecisionTree';
import Footer from '../components/Footer';

import apiHelp from '../api-help'

export default {
    name: 'SupportResult',
    data() {
        return {
            contentMisc: []
        }
    },
    mounted() {
        apiHelp.contentMisc().then(res => { this.contentMisc = res });
    },
    components: {
        BannerSearch,
        DecisionTree,
        Footer,

    }
};
</script>