import axios from 'axios'

// https://www.du.ae/
// http://meyslclvweb01:9000/

var url = "https://www.du.ae/servlet/duaediscovery/common/km/common/static/helpandsupport/";
var url2 = "https://help.du.ae/system/ws/v11/ss/";
var portalID = "201600000001018";
var portalID2 = "201600000001020";

var complaintPortal = "201600000001013"
var complaintPortalAr = "201600000001017"

var countUrl = "https://www.du.ae/servlet/duaediscovery/common/km/rating/Portal_ID_"
var isArabic = document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
var KmLang = isArabic ? 'ar' : 'en'


// preview url
// var kmuccData = "/servlet/duaediscovery/kmucc/data/"

// production url
var kmuccData = "/servlet/duaediscovery/common/km/common/static/helpandsupport/data/"

let localeEn = "en-US";
let localeAr = "ar-SA";

let usertype = window.location.href.indexOf("business") > -1 ? 'business' : 'consumer'

// let dev = "https://172.24.245.82:8090"
// let TQC = "http://172.24.245.83:8100"
// let dev2 = "http://172.24.108.102:8100"
// let preview = "http://meyslclvweb01:9000"
// prod = "https://www.du.ae"
let domain = "https://www.du.ae"

// let varComplaintPortalId = isArabic? portalID2: portalID;
// let varLanguage = isArabic? localeAr: localeEn

export default {

    // Topics API call - home page
    async getKmuccTopics() {
        return await axios.get(domain + "/kmsearchtopic?tagname=dutopic_" + usertype + "&lang=" + KmLang + "&usertype=" + usertype).then(response => {
            return response;
        })
    },
    // SubTopics API call - 2nd page Sub Topics page
    async getKmuccSubTopics(topicName) {
        return await axios.get(domain + "/kmsearchtopic?tagname=dutopic_" + topicName + "&lang=" + KmLang + "&usertype=" + usertype).then(response => {
            return response;
        })
    },
    // SubTopics API call - 2nd page Sub Topics page without multiple sub topics
    async getKmuccQuery(subTopicName) {
        return await axios.get(domain + "/kmsearchquery?tagname=dutopic_" + subTopicName + "&lang=" + KmLang + "&usertype=" + usertype).then(response => {
            return response;
        })
    },
    // Articles API call - Article details page
    async getKmuccArticle(id, lang, tagname) {
        return await axios.get(domain + '/kmsearcharticle?artid=' + id + '&lang=' + lang + '&tagname=' + tagname + "&usertype=" + usertype).then(response => {
            return response;
        })
    },
    // Search API call - search page
    async kmuccSearch(searchQuery, lang) {
        return await axios.get(domain + '/kmsearchquery?query=' + searchQuery + '&userType=consumer&lang=' + lang + "&usertype=" + usertype).then(response => {
            return response;
        })
    },
    getkmSession() {
        let varPortalId = isArabic ? portalID2 : portalID;
        axios.post("https://help.du.ae/system/ws/v15/ss/portal/" + varPortalId + "/authentication/anonymous", "", {
            headers: {
                'Accept': 'application/json',
                "Content-Type": "application/json",
                'Accept-Language': isArabic ? localeAr : localeEn
            }
        }).then((response) => {
            this.setCookie('egainsessionmain', response.headers["x-egain-session"]);
        })
            .catch((error) => {
                console.log('Error while retrieving session: ' + error);
            })
    },
    kmuccSearchTree(userInput, lang) {
        return axios.get(domain + "/kmsearchdtree?query=" + userInput + "&lang=" + lang).then(response => {
            return response;
        })
    },
    kmuccTreeNavigate(component, reference, navigateUrl, lang) {
        return axios.get(domain + "/kmdtreenavigate?component=" + component + "&reference=" + reference + "&navigateurl=" + navigateUrl + "&lang=" + lang).then(response => {
            return response;
        })
    },

    kmSession() {
        if (this.getCookie('egainsessionmain') === '') {
            this.getkmSession();
            console.log('Done');
        } else {
            console.log(this.getCookie('egainsessionmain'));
        }
    },
    getCookie(cname) {
        var name = cname + "=";
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    },

    setCookie(cname, cvalue) {
        var d = new Date();
        d.setTime(d.getTime() + (5 * 60 * 1000));
        var expires = "expires=" + d.toGMTString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    positiveCount() {
        var thumbPositiveCount = isArabic ? countUrl + portalID2 + '.json' : countUrl + portalID + '.json';
        //var thumbPositiveCount = 'http://172.24.108.102:8100//servlet/duaediscovery/~BMC/du/support/data/consumerEnThumbCount.json'
        return axios.get(thumbPositiveCount).then(response => {
            return response.data.Sheet1;
        })
    },
    getContextualHelp() {
        var contextualHelpData = isArabic ? url + 'contextualHelpAr.json' : url + 'contextualHelp.json'
        return axios.get(contextualHelpData).then(response => {
            return response.data
        })
    },

    contentMisc() {
        // var contentMiscURL = isArabic ? url + 'ar/search.json' : url + 'en/search.json';
        var contentMiscURL = isArabic ? domain + kmuccData + 'ar/contentMisc.json' : domain + kmuccData + 'en/contentMisc.json';
        // var contentMiscURL = isArabic ? '../data/ar/contentMisc.json':'../data/en/contentMisc.json';
        return axios.get(contentMiscURL).then(response => {
            return response.data.data;
        })
    },

    bannerHelp() {
        var bannerHelpURL = isArabic ? url + 'ar/banner.json' : url + 'en/banner.json';
        return axios.get(bannerHelpURL).then(response => {
            return response.data.data;
        })
    },

    howToVideos() {
        var howToVideosURL = isArabic ? url + 'ar/videos.json' : url + 'en/videos.json';
        return axios.get(howToVideosURL).then(response => {
            return response.data.data;
        })
    },

    contactHelp() {
        var contactHelpURL = isArabic ? url + 'ar/chatbot.json' : url + 'en/chatbot.json';
        return axios.get(contactHelpURL).then(response => {
            return response.data.data;
        })
    },

    getHelp() {
        var getHelpURL = isArabic ? url + 'ar/gethelp.json' : url + 'en/gethelp.json';
        return axios.get(getHelpURL).then(response => {
            return response.data.data;
        })
    },

    getTrendArticles() {
        var getTrendArticlesURL = isArabic ? url + 'ar/articles.json' : url + 'en/articles.json';
        return axios.get(getTrendArticlesURL).then(response => {
            return response.data;
        })
    },


    // announcementsList() {
    //     var announcementsListURL = isArabic ? 'https://run.mocky.io/v3/13abc641-ce53-40f6-bdfa-7c85812bb6de' : 'https://run.mocky.io/v3/3b42a8a2-1379-4450-b8c6-dd584bc215db';
    //     return axios.get(announcementsListURL).then(response => {
    //         return response.data.data;
    //     })
    // },


    getHelpTopics() {
        this.kmSession(); //set km Session

        var getHelpTopicsURL = isArabic ? url2 + 'topic?$attribute=&$lang=' + localeAr + '&$level=0&$pagenum=0&$pagesize=75&portalId=' + portalID2 + '&usertype=customer' : url2 + 'topic?$attribute=&$lang=' + localeEn + '&$level=0&$pagenum=0&$pagesize=75&portalId=' + portalID + '&usertype=customer';
        return axios.get(getHelpTopicsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    },
    getComplaintTopics() {
        this.kmSession(); //set km Session
        var getComplaintTopicsURL = isArabic ? url2 + 'topic?$attribute=&$lang=' + localeAr + '&$level=0&$pagenum=0&$pagesize=75&portalId=' + complaintPortalAr + '&usertype=customer' : url2 + 'topic?$attribute=&$lang=' + localeEn + '&$level=0&$pagenum=0&$pagesize=75&portalId=' + complaintPortal + '&usertype=customer'
        return axios.get(getComplaintTopicsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    },
    getComplaintArticle(id) {
        this.kmSession(); //set km Session

        var getArticleURL = isArabic ? url2 + 'article/' + id + '?portalId=' + complaintPortalAr + '&usertype=customer&$lang=' + localeAr : url2 + 'article/' + id + '?portalId=' + complaintPortal + '&usertype=customer&$lang=' + localeEn
        return axios.get(getArticleURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    },
    getComplaintSubTopics(id) {
        this.kmSession(); //set km Session

        var topicDetailsURL = isArabic ?
            url2 + 'article?$attribute=name,id,additionalInfo,articleKeywords,contentText,content,imageUrl,articleTypeAttributes&$lang=' + localeAr + '&context=topic_tree_click_topic&portalId=' + complaintPortalAr + '&topicId=' + id + '&usertype=customer' :
            url2 + 'article?$attribute=name,id,additionalInfo,articleKeywords,contentText,content,imageUrl,articleTypeAttributes&$lang=' + localeEn + '&context=topic_tree_click_topic&portalId=' + complaintPortal + '&topicId=' + id + '&usertype=customer'
        return axios.get(topicDetailsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    },
    complaintTopicDetails(id) {
        this.kmSession(); //set km Session

        var topicDetailsURL = isArabic ? url2 + 'topic/' + id + '?portalId=' + complaintPortalAr + '&usertype=customer&$level=1&$pagenum=0&$pagesize=50&$lang=' + localeAr : url2 + 'topic/' + id + '?portalId=' + complaintPortal + '&usertype=customer&$level=1&$pagenum=0&$pagesize=50&$lang=' + localeEn;
        return axios.get(topicDetailsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            var strObj = JSON.stringify(response.data).replace(/\\r/g, "").replace(/\\t/g, "").replace(/\\n/g, "");
            var obj = new Object();
            obj = JSON.parse(strObj);
            return obj;
        })
    },

    getQuestions(query) {
        this.kmSession(); //set km Session

        var getQuestionsURL = isArabic ?
            url2 + 'search/autocomplete?portalId=' + portalID2 + '&usertype=customer&maxCount=5&excludeType=topic&expanded=true&q=' + query :
            url2 + 'search/autocomplete?portalId=' + portalID + '&usertype=customer&maxCount=5&excludeType=topic&expanded=true&q=' + query
        return axios.get(getQuestionsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            var strObj = JSON.stringify(response.data).replace(/\\r/g, "").replace(/\\t/g, "").replace(/\\n/g, "");
            var obj = new Object();
            obj = JSON.parse(strObj);
            return obj;
        })
    },

    topicDetails(id) {
        this.kmSession(); //set km Session

        var topicDetailsURL = isArabic ? url2 + 'topic/' + id + '?portalId=' + portalID + '&usertype=customer&$level=1&$pagenum=0&$pagesize=50&$lang=ar-SA' : url2 + 'topic/' + id + '?portalId=' + portalID + '&usertype=customer&$level=1&$pagenum=0&$pagesize=50&$lang=en-US';
        return axios.get(topicDetailsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            var strObj = JSON.stringify(response.data).replace(/\\r/g, "").replace(/\\t/g, "").replace(/\\n/g, "");
            var obj = new Object();
            obj = JSON.parse(strObj);
            return obj;
        })
    },

    getSubTopics(id) {
        this.kmSession(); //set km Session

        var topicDetailsURL = isArabic ?
            url2 + 'article?$attribute=name,id,additionalInfo,articleKeywords,contentText,content,imageUrl,articleTypeAttributes&$lang=ar-SA&context=topic_tree_click_topic&portalId=' + portalID + '&topicId=' + id + '&usertype=customer' :
            url2 + 'article?$attribute=name,id,additionalInfo,articleKeywords,contentText,content,imageUrl,articleTypeAttributes&$lang=en-US&context=topic_tree_click_topic&portalId=' + portalID + '&topicId=' + id + '&usertype=customer'
        return axios.get(topicDetailsURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    },

    getArticle(id) {
        this.kmSession(); //set km Session

        var getArticleURL = isArabic ? url2 + 'article/' + id + '?portalId=' + portalID + '&usertype=customer&$lang=ar-SA' : url2 + 'article/' + id + '?portalId=' + portalID + '&usertype=customer&$lang=en-US'
        return axios.get(getArticleURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    },

    getSearchResult(query) {
        this.kmSession(); //set km Session

        var getSearchResultURL = isArabic ? url2 + 'search/kb?portalId=' + portalID2 + '&usertype=customer&$pagesize=50&resourceType=article&lang=&lang=ar-SA&q=' + query : url2 + 'search/kb?portalId=' + portalID + '&usertype=customer&$pagesize=50&resourceType=article&q=' + query
        return axios.get(getSearchResultURL, {
            headers: {
                'Accept': 'application/json',
                'X-egain-session': this.getCookie('egainsessionmain')
            }
        }).then(response => {
            return response.data;
        })
    }
}