<template>
    <section class="banner-search" @click="clearInput()">
        <div class="banner-search-inner">
            <div class="grid-container narrow">
                <template v-if="bannerHelp.title">
                    <form class="banner-search-form" :action="actionURL">
                        <label for="searchHelp" class="hidden">{{bannerHelp.title}}</label>
                        <button :class="{'banner-search-btn btn white':true, 'disabled': searchText.length < 3 ? true : false }" @click="sendGA('Help & Support' , 'Search Term', searchText, 0)">
                            {{bannerHelp.title}}
                        </button>
                        <input  type="search"
                                id="searchHelp"
                                class="banner-search-input" 
                                name="q"
                                autocomplete="off"
                                :placeholder="bannerHelp.subtitle"
                                v-model="searchText" 
                                @input="changeQuestions()" 
                                @focus="suggestionsOpen = true"
                                @keydown.enter = 'enter'
                                @keydown.down = 'down'
                                @keydown.up = 'up'
                                @keydown.space="preventLeadingSpace">
                        <ul class="banner-search-suggestion" v-if="searchText.length > 0">
                            <template v-if="suggestionsOpen && questionsSuggestion.length == 0">
                              <div class="text-center lds-ring-container">
                                <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
                              </div>
                            </template>
                            <template v-for="(question,i) in questionsSuggestion" v-else >
                                <li :class="{'active': isActive(i+1)}" :key="i">
                                     <a v-if="lang === 'ar'" :href="question.type === 'Article' || question.type === 'FAQ'? '/ar/support-articledetail?userType='+ userType +'&'+question.articleURL : '/ar/decisionTree?userType='+ userType +'&query='+question.name" @click="sendGA('Help & Support' , 'Search Article', question.suggestion, 0)">{{question.name}}</a>
                                     <a v-else :href="question.type === 'Article'|| question.type === 'FAQ'? '/support-articledetail?userType='+ userType +'&'+question.articleURL : '/decisionTree?userType='+ userType +'&query='+question.name" @click="sendGA('Help & Support' , 'Search Article', question.suggestion, 0)">{{question.name}}</a>
                                </li>
                            </template>
                        </ul>
                    </form>
                </template>
                <template v-else>
                    <form class="banner-search-form rd-loader">
                        <input type="" name="" class="banner-search-input">
                    </form>
                </template>
            </div>
        </div>
    </section>
</template>
<script>
import apiHelp from '../../api-help'
import { Analytics } from "@/analytics.js";

export default {
    name: 'BannerSearch',
    data() {
        return {
            bannerHelp: [],
            searchText: '',
            questionsSuggestion: [],
            suggestionsOpen: false,
            current: 0,
            actionURL: null,
            lang: document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl' ? 'ar':'en',
            userType : window.location.href.indexOf("business") > -1 ? 'business' : 'consumer',
        }
    },
    mounted() {
        apiHelp.bannerHelp().then(res => {
          this.bannerHelp = res
        });
        let params = new URLSearchParams(document.location.search);
        if(params.get("q")){
          this.searchText = params.get("q");
        }
        if(params.get("userType")){
          console.log('has parameter')
        }
        if(this.userType){
          console.log('userType', this.userType);
          this.actionURL = "support-searchresult?userType="+ this.userType+"&q="
        }
    },
    methods: {
         preventLeadingSpace(e) {
          if (!e.target.value) e.preventDefault();
          else if (e.target.value[0]==' ') e.target.value = e.target.value.replace(/^\s*/, "");
        },
        sendGA(eventCategory, eventAction, eventLabel, eventValue) {
            Analytics(eventCategory, eventAction, eventLabel, eventValue);
        },
        clearInput(){
            // this.searchText = "bill";
            this.questionsSuggestion = [];
        },
        changeQuestions() {
            if (this.searchText.length > 2) {
              console.log(this.searchText, this.lang)
                apiHelp.kmuccSearch(this.searchText, this.lang).then(res => {
                  //debugger;
                  //   var newArray = []
                  console.log(res.data.articles)
                    this.questionsSuggestion = res.data.articles
                });
            }
            if (this.searchText == "") {
                this.questionsSuggestion = [];
            }
        },
        up() {
            if(this.current > 0)
                this.current--;
        },

        down() {
            if(this.current < this.questionsSuggestion.length)
                this.current++;
        },
        enter(e) {
            if (this.searchText.length < 3){
                 e.preventDefault();
            }
            if (this.current > 0){
                e.preventDefault();
                this.selection = this.questionsSuggestion[this.current];
                this.suggestionsOpen = false;
                var url = this.bannerHelp.url+this.questionsSuggestion[this.current].alternateId+this.bannerHelp.language;
                window.location.href = url;
            }

        },
        isActive(index) {
            return index === this.current;
        },
    }
}
</script>
<style lang="scss">
body {
  .banner-search {
    position: relative;
    padding: 64px 0;
    display: flex;
    flex-direction: column;
    max-width: 1920px;
    margin: 0 auto;
    background: linear-gradient(315deg, #753BBD 0%, #00205b 67.21%, #00205b 100%);

    @media only screen and (max-width: $phone-max-size) {
      padding: 20px 0;
      min-height: 110px;
      height: 110px;

    }

    input {
      -webkit-appearance: none;
    }

    &-suggestion {
      background-color: $white;
      position: absolute;
      box-shadow: -2px 2px 10px rgba(0, 0, 0, 0.2);
      width: 100%;
      border-radius: 4px;
      z-index: 9;
      overflow: hidden;
      margin-top: 2px;

      li {
        padding: 10px 20px !important;
        position: relative;

        &:last-of-type {
          margin-bottom: 0;
        }

        &:hover, &.active {
          background: linear-gradient(315deg, #00A9CE 20%, #00A9CE 67.21%, #753BBD 100%);

          a {
            color: $white;
          }
        }

        a {
          color: $black;
          display: block;
          @media only screen and (max-width: $phone-max-size) {
            font-size: 16px;
            line-height: 16px;
          }
        }
      }
    }

    .grid-container {
      display: flex;
      position: relative;
    }

    &-inner {
      height: 100%;
      display: flex;
      flex-grow: 1;

    }

    &-form {
      width: 100%;
      position: relative;
    }

    &-input {
      width: 100%;
      height: 72px;
      background-color: rgba(255, 255, 255, 0.4) !important;
      border-radius: 12px;
      padding: 20px;
      color: $white;
      border: 0px;
      font-size: 36px;
      line-height: 36px;
      font-family: $du-font-secondary-medium;
      margin-bottom: 0;

      [dir="ltr"] & {
        padding-right: 250px;
      }

      [dir="rtl"] & {
        padding-left: 250px;
      }

      // &::-webkit-search-cancel-button {
      //   -webkit-appearance: none;
      //   content: "X";
      //   height: 10px;
      //   width: 10px;
      //   background: red;
      // }

      @media only screen and (max-width: $phone-max-size) {
        font-size: 16px;
        line-height: 16px;
        width: 100%;
        height: 40px;
        margin: 0;
        padding: 0 10px;
        border-radius: 8px;
        [dir="ltr"] & {
          padding-right: 100px;
        }
        [dir="rtl"] & {
          padding-left: 100px;
        }
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-size: 36px;
        line-height: 36px;
        font-family: $du-font-secondary-medium;

        @media only screen and (max-width: $phone-max-size) {
          font-size: 16px;
          line-height: 16px;
        }
      }
    }

    &-btn.btn.white {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 8px;
      height: 56px;

      [dir="ltr"] & {
        right: 8px;
      }

      [dir="rtl"] & {
        left: 8px;
      }

      &.disabled {
        pointer-events: none;
        opacity: .8;
      }

      @media only screen and (max-width: $phone-max-size) {
        width: 90px;
        height: 34px;
        line-height: 25px;
        min-width: 90px;
        font-size: 15px;
        top: 3px;
        transform: translateY(0);
        padding: 0;
        border-radius: 4px;

        [dir="ltr"] & {
          right: 3px;
        }
        [dir="rtl"] & {
          left: 3px;
        }

      }

      &:hover {
        color: $white;
      }

    }


  }
}
</style>